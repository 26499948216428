import 'react-medium-image-zoom/dist/styles.css';

import { HoverCard, Table } from '@radix-ui/themes';
import { Calendar, Clock, ExternalLink } from 'lucide-react';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { memo } from 'react';
import Zoom from 'react-medium-image-zoom';

import { Flag, Heading, Text } from '@/components';
import type { IArticleModel } from '@/models';
import { sendOpenArticleEvent } from '@/pages/articles/utils';

import type { IArticlesTableProps } from './interfaces';
import * as S from './styles';

function ProjectsTable({ data }: IArticlesTableProps) {
  const posthog = usePostHog();
  const router = useRouter();

  const handleOpenArticle = (article: IArticleModel) => {
    sendOpenArticleEvent(article, posthog);

    if (article.link.includes('medium')) {
      return window.open(article.link, '_blank');
    }

    return router.push(article.link);
  };

  return (
    <S.Container>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <S.Cell>
              <Text size="small">Title</Text>
            </S.Cell>

            <S.Cell hideOnMobile>
              <Text size="small">Publication date</Text>
            </S.Cell>

            <S.Cell hideOnMobile>
              <Text size="small">Read time</Text>
            </S.Cell>

            <S.Cell hideOnMobile>
              <Text size="small">Language</Text>
            </S.Cell>

            <S.Cell>
              <Text size="small">Link</Text>
            </S.Cell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map(article => (
            <Table.Row key={article.id}>
              <S.Cell>{article.title}</S.Cell>

              <S.Cell width={150} hideOnMobile>
                <S.TableItem>
                  <Text size="small">
                    <Calendar size={13} />
                    {article.publicationDate}
                  </Text>
                </S.TableItem>
              </S.Cell>

              <S.Cell width={130} hideOnMobile>
                <S.TableItem>
                  <Text size="small">
                    <Clock size={13} />
                    {article.readTime} min read
                  </Text>
                </S.TableItem>
              </S.Cell>

              <S.Cell width={130} hideOnMobile>
                <S.TableItem>
                  {article.language === 'ptBR' && <Flag.BR width={12} />}
                  <Text size="small">
                    {article.language === 'ptBR'
                      ? 'Portuguese'
                      : article.language}
                  </Text>
                </S.TableItem>
              </S.Cell>

              <S.Cell width={150}>
                <HoverCard.Root>
                  <HoverCard.Trigger>
                    <S.TableItem>
                      <Text size="small">
                        <a onClick={() => handleOpenArticle(article)}>
                          Read article
                          <ExternalLink size={13} />
                        </a>
                      </Text>
                    </S.TableItem>
                  </HoverCard.Trigger>

                  <HoverCard.Content>
                    <S.PreviewWrapper>
                      <Zoom>
                        <S.ImagePreview
                          src={article.image}
                          alt={article.title}
                        />
                      </Zoom>
                      <Heading level={6}>{article.title}</Heading>
                      <Text size="small">
                        {article.preview.length > 300
                          ? `${article.preview.slice(0, 300)}...`
                          : `${article.preview}...`}
                      </Text>
                      <S.TableItem>
                        <Text size="small">
                          <a onClick={() => handleOpenArticle(article)}>
                            Read article <ExternalLink size={14} />
                          </a>
                        </Text>
                      </S.TableItem>
                    </S.PreviewWrapper>
                  </HoverCard.Content>
                </HoverCard.Root>
              </S.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </S.Container>
  );
}

export default memo(ProjectsTable);
