import styled, { css } from 'styled-components';

interface CellProps {
  hideOnMobile?: boolean;
  width?: number;
}

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: relative;
    margin-top: 2.4rem;
    border: 0.1rem solid ${({ theme }) => theme.colors.borderColor};
    border-radius: 0.8rem;

    tbody {
      tr {
        &:last-child {
          --table-row-box-shadow: none;
        }
      }
    }

    @media (max-width: ${theme.breakpoints.medium}) {
      margin-top: 1.6rem;
    }
  `}
`;

export const ImagePreview = styled.img`
  width: 100%;
  border-radius: 0.4rem;
`;

export const PreviewWrapper = styled.div`
  max-width: 50rem;
  display: grid;
  gap: 2.4rem;
`;

export const TableItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  p,
  a {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
`;

export const Cell = styled.th<CellProps>`
  background-color: var(--table-row-background-color);
  box-shadow: var(--table-row-box-shadow);
  box-sizing: border-box;
  vertical-align: inherit;
  padding: var(--table-cell-padding);
  height: var(--table-cell-min-height);
  font-weight: normal;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    &:last-child {
      width: 13rem;
    }

    ${({ hideOnMobile }) =>
      hideOnMobile &&
      css`
        display: none;
      `};
  }
`;
