import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const ArticlesWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 2fr;
    justify-content: space-between;
    column-gap: 4.2rem;
    margin-top: 2.4rem;

    @media (max-width: ${theme.breakpoints.large}) {
      grid-template-columns: 1fr;
      row-gap: 4rem;
      margin-top: 1.6rem;
    }
  `}
`;

export const AllArticlesWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 2fr;
    justify-content: space-between;
    gap: 4.2rem;
    margin-top: 2.4rem;

    @media (max-width: ${theme.breakpoints.large}) {
      grid-template-columns: 1fr;
      row-gap: 4rem;
      margin-top: 1.6rem;
    }
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    :hover {
      svg {
        fill: ${({ theme }) => theme.colors.highlightColor};
      }
    }
  }
`;
